.persons-table {
  td,th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  tr td:first-child {
    padding-left: 3rem;
  }

  .top-cell {
    background: var(--main-header-color);
  }

}

