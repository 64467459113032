table{
  width: 100%;
  border-collapse: collapse;
  font-size: 1.5rem;
}

.content {
  max-width: 100%;
  margin: auto;
  position: relative;
}